$('.no-js').removeClass('no-js').addClass('js');

/* SVG Support for IE */
svg4everybody();

// Cookie-Hinweis.
function setCookie(cname, cvalue, exdays = 0) {
  var date, expires;
  if (exdays) {
    date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = ' expires=' + date.toGMTString();
  }
  document.cookie = cname + '=' + cvalue + '; path=/;' + expires;
}

function hasCookieVal(cname, val) {
  if (document.cookie.indexOf(cname + '=' + val) >= 0) {
    return true;
  }
  return false;
}

function hasCookie(cname) {
  if (hasCookieVal(cname, 'true')) {
    return true;
  }
  return false;
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function hasCookieSplitVal(cname, val) {
  var value = getCookie(cname);
  var split = value.split('|');
  for (var i = 0; i < split.length; i++) {
    var c = split[i];
    if (c.indexOf(val) == 0) {
      return true;
    }
  }
  return false;
}

function addCookieSplitVal(cname, val, exdays = 0) {
  var newval;
  var value = getCookie(cname);
  if (value) {
    var split = value.split('|');
    for (var i = 0; i < split.length; i++) {
      var c = split[i];
      if (c == val) {
        return false;
      }
    }
    split.push(val);
    newval = split.join('|');
  } else {
    newval = val;
  }
  setCookie(cname, newval, exdays);
}

function removeCookieSplitVal(cname, val, exdays = 0) {
  var value = getCookie(cname);
  var split = value.split('|');
  var filtered = split.filter(function(value, index, arr) {
    return value != val;
  });
  var newval = filtered.join('|');
  setCookie(cname, newval, exdays);
}

function removeCookie(cname) {
  document.cookie = cname + '= ;  path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT';
}

/**
 * Add hash to url without scrolling
 *
 * @param String $url - it could be hash or url with hash
 *
 * @return void
 */
function addHashToUrl($url) {
  if ('' == $url || undefined == $url) {
    $url = '_'; // it is empty hash because if put empty string here then browser will scroll to top of page
  }
  $hash = $url.replace(/^.*#/, '');
  var $fx, $node = $('#' + $hash);
  if ($node.length) {
    $fx = $('<div></div>')
      .css({
        position: 'absolute',
        visibility: 'hidden',
        top: $(window).scrollTop() + 'px'
      })
      .attr('id', $hash)
      .appendTo(document.body);
    $node.attr('id', '');
  }
  document.location.hash = $hash;
  if ($node.length) {
    $fx.remove();
    $node.attr('id', $hash);
  }
}

// debulked onresize handler
// function on_resize(c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,100)};return c};
//
// var mobile = true;
//
// if (window.innerWidth >= 1440) {
// 	mobile = false
// }
//
// on_resize(function() {
//   // handle the resize event here
//   if (window.innerWidth >= 1440) {
//   	mobile = false
//   } else {
// 		focusout_close();
// 		mobile = true
//   }
// })();

var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
var focusedElementBeforeModal;

function trapTabKey(obj, evt) {
	if (evt.which == 9) {
			var o = obj.find("*");
			var focusableItems;
			focusableItems = o.filter(focusableElementsString).filter(":visible")
			var focusedItem;
			focusedItem = $(":focus");
			var numberOfFocusableItems;
			numberOfFocusableItems = focusableItems.length
			var focusedItemIndex;
			focusedItemIndex = focusableItems.index(focusedItem);

			if (evt.shiftKey) {
					if (focusedItemIndex == 0) {
							focusableItems.get(numberOfFocusableItems - 1).focus();
							evt.preventDefault();
					}

			} else {
					if (focusedItemIndex == numberOfFocusableItems - 1) {
							focusableItems.get(0).focus();
							evt.preventDefault();
					}
			}
	}
}

function setInitialFocusModal(obj) {
	var o = obj.find("*");
	var focusableItems;
	focusableItems = o.filter(focusableElementsString).filter(":visible").first().focus();
}

function setFocusToFirstItemInModal(obj){
	var o = obj.find("*");
	o.filter(focusableElementsString).filter(":visible").first().focus();
}

function showModal(content, overlay, modal, displayMode = 'flex') {
	$(content).attr("aria-hidden", "true");
	$(overlay).css("display", "block");
	$(modal).css("display", displayMode);
	$(modal).attr("aria-hidden", "false");
	$("body").on("focusin",content,function() {
			setFocusToFirstItemInModal($(modal));
	})

	focusedElementBeforeModal = $(":focus");
	setFocusToFirstItemInModal($(modal));
}

function hideModal(content, overlay, modal) {
	$(overlay).css("display", "none");
	$(modal).css("display", "none");
	$(modal).attr("aria-hidden", "true");
	$(content).attr("aria-hidden", "false");
	$("body").off("focusin",content);
	focusedElementBeforeModal.focus();
}


$('.menu-button').click(function(e) {
    $(this).toggleClass('active');
  $('#' + $(this).data('for')).slideToggle();
});

// Untermenü
$('.menu-main').children('.has-sub').children('a').click(function(e) {
  e.preventDefault();
  console.log('hi');
  var parent = $(this).parent(),
  sub = parent.children('.sub-container');

  if (parent.hasClass('open')) {
    parent.removeClass('open');
    sub.slideUp('fast');
  } else {

    var open_menu = $('#menu-main').find('.open');

    if (open_menu.length > 0) {
      open_menu.removeClass('open').children('.sub-container').slideUp('fast', function() {
        parent.addClass('open');
        sub.slideDown('fast');
      });
    } else {
      parent.addClass('open');
      sub.slideDown('fast');
    }

  }
});

// Suchfeld
$('.btn-suche').children('a').click(function(e) {
  e.preventDefault();
  console.log('hello');
  $(this).toggleClass('active');

  var search = $('.search');

  if (search.hasClass('open')) {
    search.removeClass('open').slideUp('fast');
  } else {
    search.addClass('open').slideDown('fast');
    $('.search input').focus();
  }
});

$('.btn-cancel').click(function(e) {
  e.preventDefault();

  $('.search').removeClass('open').slideUp('fast');
  $('.search-btn a').removeClass('active').focus();
});

// Mit Esc-Taste schließen
$(document).on('keyup', function(e) {
  if (e.keyCode == 27 && $('.search').hasClass('open')) {
    $('.search').removeClass('open').slideUp('fast');
    $('.search-btn a').focus();
  }
});

$(document).ready(function() {
  var template_path = php_vars.json_data;
  // console.log(template_path);
  // Aktiviert Lightbox für Einzelbild.
  $('.wp-block-image a').featherlight();
  $('.wp-block-image a').append('<svg role="img" class="symbol" aria-hidden="true" focusable="false"><use xlink:href="' + template_path + '/img/icons.svg#expand"></use></svg>');

  // Aktiviert Lightbox für Galerie.
  $('.blocks-gallery-item a').featherlightGallery({
    previousIcon: '&#9664;',
    /* Code that is used as previous icon */
    nextIcon: '&#9654;',
    /* Code that is used as next icon */
    galleryFadeIn: 100,
    /* fadeIn speed when slide is loaded */
    galleryFadeOut: 300 /* fadeOut speed before slide is loaded */
  });

// Add Caption to Lightbox.
$.featherlightGallery.prototype.afterContent = function () {
        var caption = this.$currentTarget.parent().find('figcaption').text();
        this.$instance.find('.caption').remove();
        $('<div class="caption">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
    }
    $.featherlight.prototype.afterContent = function () {
            var caption = this.$currentTarget.parent().find('figcaption').text();
            this.$instance.find('.caption').remove();
            $('<div class="caption">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
        }

  //Add wrapper class to slideshow
  $('.slideshow').addClass('wrapper');
 $('.blocks-gallery-item a').append('<svg role="img" class="symbol" aria-hidden="true" focusable="false"><use xlink:href="' + template_path + '/img/icons.svg#expand"></use></svg>');
});
// Öffnet die alten Bildergalerien.
$(document).ready(function() {
    $('.g_link').nivoLightbox();
});

/* German initialisation for the jQuery UI date picker plugin. */
/* Written by Milian Wolff (mail@milianw.de). */
( function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define( [ "../widgets/datepicker" ], factory );
	} else {

		// Browser globals
		factory( jQuery.datepicker );
	}
}( function( datepicker ) {

  var uri = $('html').data('uri');

datepicker.regional.de = {
	closeText: "Schließen",
	prevText: "Zurück",
	nextText: "Vor",
	currentText: "Heute",
	monthNames: [ "Januar","Februar","März","April","Mai","Juni",
	"Juli","August","September","Oktober","November","Dezember" ],
	monthNamesShort: [ "Jan","Feb","Mär","Apr","Mai","Jun",
	"Jul","Aug","Sep","Okt","Nov","Dez" ],
	dayNames: [ "Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag" ],
	dayNamesShort: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
	dayNamesMin: [ "So","Mo","Di","Mi","Do","Fr","Sa" ],
	weekHeader: "KW",
	dateFormat: "dd.mm.yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "",
};

datepicker.setDefaults( datepicker.regional.de );

return datepicker.regional.de;

} ) );

$("#datepicker").hide();

$("#datepicker-btn").click(function(){

    $("#datepicker").toggle();
});

$("#datepicker").datepicker({
  altField: "#date",
  altFormat: "yy-mm-dd",
  defaultDate: new Date($("#date").val()),
      onSelect: function(value, date) {
         $("#datepicker").hide();
         $("#date").trigger('change');
      }
});

$('.refresh').change(function () {
    $("#eventsfilter").submit();
});

// $("#datepicker-btn span").text(function() {
//   var date = new Date($("#date").val());
//   return "ab " + date.toLocaleDateString(date);
// });

/* Grid Karte */
function initMaps() {
    var locationLists = document.getElementsByClassName('locations');

    Array.prototype.forEach.call(locationLists, function(locationList) {
        var mapCanvas = locationList.getElementsByClassName('map')[0];
        var mapOptions = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 15
        }
        var infoWindow = new google.maps.InfoWindow();
        var map = new google.maps.Map(mapCanvas, mapOptions);
        var latLngBounds = new google.maps.LatLngBounds();

        var addressList = locationList.getElementsByClassName('address');


        Array.prototype.forEach.call(addressList, function(address) {
            var lat = address.getElementsByClassName("lat")[0].getAttribute("content"),
                lng = address.getElementsByClassName("lng")[0].getAttribute("content"),
                ttl = address.getElementsByTagName('h3')[0].textContent;

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                title: ttl,
                draggable: false,
                map: map
            });

            google.maps.event.addListener(marker, "click", function(e) {
                infoWindow.setContent('<p><strong>' + ttl + '</strong></p><a href="http://maps.google.de/?q=' + lat + ',' + lng + '" target="_blank">Auf Google Maps anzeigen</a>');
                infoWindow.open(map, marker);
            });

            latLngBounds.extend(marker.position);
        });

        var bounds = new google.maps.LatLngBounds();
        if (addressList.length > 1) {
            map.fitBounds(latLngBounds);
        }
        map.setCenter(latLngBounds.getCenter());
    });

}

if ($(".locations").length > 0) {
    var script = document.createElement('script');
    script.src = '//maps.googleapis.com/maps/api/js?key=AIzaSyDDQGKWQAkBBNhSVlXZgFlD_UYwXFKAFZc&callback=initMaps';
    document.body.appendChild(script);
}
